.form-control {
    display: block;
    width: 100% !important;
    padding: 0.375rem 0.75rem;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background: rgba(0, 115, 187, 0.10) !important;
    border:0px var(--bs-border-width) solid var(--bs-border-color) !important;
    border-radius: var(--bs-border-radius);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    /* font-weight: bold  !important; */
    /* margin-left: -20px !important; */
}

#fname{
    background: rgba(0, 115, 187, 0.10) !important;
}

#fname1{
    background: rgba(187, 3, 0, 0.1) !important;
}

#fname{
    background: rgba(0, 115, 187, 0.10) !important;
}




#fname:focus{
    background: rgba(0, 115, 187, 0.10) !important;
}

#fname1:focus{
    background: rgba(187, 3, 0, 0.1) !important;
}
.rangeslider-horizontal .rangeslider__fill {
    height: 100%;
    background-color: #0073BB !important;
    border-radius: 10px;
    top: 0;
}

/* .rangeslider-horizontal {
    height: 4px !important;
    border-radius: 10px;
} */

.rangeslider-horizontal .rangeslider__handle {
    width: 20px !important;
    height: 20px !important;
    border-radius: 30px;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
}

.rangeslider .rangeslider__handle {
    background: #0073BB !important;
    border: 0px solid #ccc !important;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    box-shadow: none !important;
}

.rangeslider-horizontal .rangeslider__handle:after {
    display: none !important;
    content: ' ';
    position: absolute;
    width: 16px;
    height: 16px;
    top: 6px;
    left: 6px;
    border-radius: 50%;
    background-color: #dadada;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4) inset, 0 -1px 3px rgba(0, 0, 0, 0.4) inset;
}

.rangeslider__handle-tooltip{
    display: none !important;
}


.apexcharts-datalabels{
    display: none;
}


.apx-legend-position-right{
    position: absolute;
    left: auto;
    top: 80px !important;
    right: 5px;
    text-align: initial;
}


/* input {
    background: rgba(0, 115, 187, 0.10);
    border: none;
    border-radius: 5px;
} */
.sm-fl{
    width: 30px !important;
    margin-left: 8px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    /* background-color: rgba(0, 115, 187, 0.10) !important; */
}

.sm-fr{
    width: 35px !important;
    margin-left: 8px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    /* background-color: rgba(0, 115, 187, 0.10) !important; */
    margin-left: 0px !important;
}

.sm-bg{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.sm-bgr{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    margin-left: 10px !important;
}

@media only screen and (min-width : 400px) and (max-width: 430px) {
    .apexcharts-legend{
        left: auto;
        top: 0!important;
        right: 60px!important;
    }
    .donut-chart{
        margin-left: 0px !important;
    }
}
.circle-blue {
    height: 17px;
    width: 17px;
    background-color: #0073BB;
    border-radius: 50%;
    margin-top: 5px;
    margin-right: 10px;
}
.circle-pink {
    height: 17px;
    width: 17px;
    background-color: rgb(224, 87, 118);
    border-radius: 50%;
    margin-top: 5px;
    margin-right: 10px;
}
/* .circle-grey {
    height: 17px;
    width: 17px;
    background-color: rgb(171, 171, 171);
    border-radius: 50%;
    margin-top: 5px;
    margin-right: 10px;
} */
.apexcharts-inner{
    /* width: 350px !important;
    height: 233.7px !important;*/
    margin-top: 38px !important; 
}
.apexcharts-legend{
    display: none !important;
}
.wdthcls{
    width: 26px !important;
    padding-left: 2px !important;
    height:30px ! important;
    display: block !important;
    padding-top: 5px !important;
}

*{
  font-size: 14px;
 }

 