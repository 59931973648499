/* @import url('https://fonts.googleapis.com/css?family=Montserrat'); */

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: 'Montserrat', sans-serif;
  background-color: #34495e;
  color: #fff;
}
h1 {
  text-align: center;
  margin: 2rem 0;
  font-size: 2.5rem;
} */

.accordion {
  width: 100%;
  max-width: 1000px;
  margin: 2rem auto;
}
.accordion-item {
  background-color: #fff;
  color: #111;
  margin: 0rem 0;
  border-radius: 0.0rem;
  /* box-shadow: 0 2px 5px 0 rgba(0,0,0,0.25); */
}
.accordion-item-header {
  padding: 0.5rem 3rem 0.5rem 1rem;
  min-height: 3.5rem;
  line-height: 1.25rem;
  /* font-weight: bold; */
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.accordion-item-header::after {
  content: "▲";
  /* font-size: 2rem; */
  position: absolute;
  right: 1rem;
}
.accordion-item-header.active::after {
  content: "▼";
}
.accordion-item-body {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.accordion-item-body-content {
  padding: 1rem;
  line-height: 1.5rem;
  border-top: 1px solid;
  border-image: linear-gradient(to right, transparent, #34495e, transparent) 1;
}
.accordion-item-header.active{
    background: #0073bbd7 !important;
    color: white;
}

@media(max-width:767px) {
  html {
    font-size: 14px;

  }
 
}

.info-icons{
  width:15px !important;
  padding-bottom: 4px !important;
}


#retirment-tool-id .react-tooltip{
  z-index: 9999 !important;
  /* width: 80px!important; */
  font-size: 10px !important;
}