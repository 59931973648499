.cross-icon {
  position: absolute;
  top: -8px;
  right: -5px;
  background: #f3f3f3;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.choose-sum {
  display: flex;
  border-radius: 8px;
  width: 10%;
}

.rel-icon {
  margin-top: 50px;
  border-radius: 11px;
  padding: 15px;
  cursor: pointer;
}
.cross-ico {
  position: relative;
  width: 25px;
  /* right: 15px; */
  cursor: pointer;
  z-index: 10px;
  margin-left: -25px;
  margin-top: -49px;
}

#myform {
  text-align: center;
  padding: 5px;
  margin: 2%;
}
.qty {
  width: 27px;
  height: 25px !important;
  text-align: center;
  padding-top: 0px !important;
  margin-left: 4px !important;
  margin-right: 4px !important;
}
input.qtyplus {
  width: 25px;
  height: 25px !important;
  padding-top: 0px !important;
}
input.qtyminus {
  width: 25px;
  height: 25px !important;
  padding-top: 0px !important;
}



.plan-rel-icon {
  background: rgb(223, 235, 246);
  width: 38px;
  height: 38px;
  padding: 5px;
  border-radius: 40px;
}
#input-date-health {
  /* width: 80% !important; */
  margin-top: 3px;
}

.plan-cross-ico {
  width: 35px;
  height: 28px;
  margin-top: 4px;
}
#detail-label {
  color: #0073bb;
  font-size: 18px !important;
}
.background-div {
  background-color: #0073bb;
  color: #ffffff;
  border-radius: 20px;
  width: 25rem;
  padding: 30px;
}
.background-div1 {
  background-color: rgba(0, 115, 187, 10%);
  color: #0073bb;
  border-radius: 20px;
  width: 25rem;
  padding: 30px;
}
.text-bar1 {
  border-bottom: 1px solid #0073bb;
  text-align: left;
}
.text-bar {
  border-bottom: 1px solid #ffffff;
  text-align: left;
}
.total-value {
  font-size: 30px !important;
  font-weight: 500;
  line-height: 1.2;
}

.insured-div {
  margin-left: 50px;
}

.policy-label {
  color: #3d3d3d !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

@media only screen and (min-width: 300px) and (max-width: 420px) {
  .btn-danger {
    background: #0073bb !important;
    border: 2px solid #0073bb !important;
    width: 40% !important;
    border-radius: 38px !important;
  }
  .form-label {
    margin-bottom: -0.5rem !important;
    margin-top: 0.5rem !important;
  }
}
@media only screen and (min-width: 420px) and (max-width: 430px) {
  .btn-danger {
    background: #0073bb !important;
    border: 2px solid #0073bb !important;
    width: 40% !important;
    border-radius: 38px !important;
  }
  .form-label {
    margin-bottom: -0.5rem !important;
    margin-top: 0.5rem !important;
  }
}
@media only screen and (min-width: 430px) and (max-width: 770px) {
  .btn-danger {
    background: #0073bb !important;
    border: 2px solid #0073bb !important;
    width: 40% !important;
    border-radius: 38px !important;
  }
  .qty {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
  .form-label {
    margin-bottom: -0.5rem !important;
    margin-top: 0.5rem !important;
  }
}
@media only screen and (min-width: 770px) and (max-width: 1030px) {
  .btn-danger {
    background: #0073bb !important;
    border: 2px solid #0073bb !important;
    width: 40% !important;
    border-radius: 38px !important;
  }
  .form-label {
    margin-bottom: -0.5rem !important;
    margin-top: 0.5rem !important;
  }
}

@media only screen and (min-width: 665px) and (max-width: 1030px) {
  .rel-icon {
    margin-top: 20px;
    border-radius: 11px;
    padding: 4px;
    cursor: pointer;
    margin-left: -4px;
  }
  .cross-ico {
    position: relative;
    width: 25px;
    /* right: 15px; */
    cursor: pointer;
    z-index: 10px;
    margin-left: 73px !important;
    margin-top: -205px !important;
  }
}

@media only screen and (min-width: 420px) and (max-width: 770px) {
  .cross-ico {
    position: relative;
    width: 25px;
    /* right: 15px; */
    cursor: pointer;
    z-index: 10px;
    margin-left: 99px;
    margin-top: -247px;
  }
  .link-img{
    width: 145px;
    height: 160px;
  }
}
.five-off {
  margin-left: 679px;
  font-size: 10px !important;
  color: #0073bb;
  margin-bottom: -10px;
}
.ten-off {
  margin-left: 50px;
  font-size: 10px !important;
  color: #0073bb;
  margin-bottom: -10px;
}
.input-row {
  margin-left: 70px !important;
}
.preffered {
  float: right;
  margin-top: -30px;
  margin-right: -30px;
  background-color: rgb(163, 220, 255);
  padding: 10px 20px;
  border-radius: 0px 20px;
  color: rgb(0, 115, 187);
}
.strikethrough {
  text-decoration: line-through;
}



#benifits {
  justify-content: center !important;
}
#benifits li{
  list-style: none !important;
    background: gainsboro;
    padding: 8px;
    margin: 2px;
    border-radius: 4px !important;
    font-size: 11px !important ;
    font-weight: bold;
    cursor: pointer;
}

#benifits .active {
    list-style: none !important;
    background: #0073bb !important;
    padding: 8px !important;
    margin: 2px !important;
    border-radius: 4px !important;
    font-size: 11px !important ;
    font-weight: bold !important;
    cursor: pointer !important;
    color: #f3f3f3 !important;
}
.tab-head{
  color: #0073bb;
  font-weight: 500;
}

#tabs1 li{
  color: #071e54;
  list-style: none;
  margin-top: 1px;
}

#tabs1 .info{
  cursor: pointer !important;
  color: #071e54;
  background-color: #0073bb;
  padding: 4px;
  border-radius: 50px;
  height: 30px;
  width: 30px;
  text-align: center;
  color: #f3f3f3;
}

.modal-dialog-scrollable .modal-content {
  max-height: 95% !important;
  overflow: hidden;
  margin-top: 78px !important;
}
.close-btn-med{
  width: 20% !important;
  background: #CA204B !important;
  border: 1px solid #CA204B !important;
}
.btn-danger {
  background: #0073bb !important;
  border: 2px solid #0073bb !important;
  width: 98% !important;
  border-radius: 10px !important;
}
.paraa{
  color: rgb(0 115 187 / 95%);
  font-weight: 600;
  cursor: pointer;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
  height: 16px !important;
  width: 16px !important;
  border: 1px solid rgb(0 115 187 / 95%);
}
#cov{
  /* width: 80% !important; */
}
#ages{
  color: rgb(93 91 91) !important;
  font-size: 13px !important;
  font-weight: 700;
}
#a-link{
  text-decoration: underline !important;
}
img.link-img {
  padding: 12px !important;
}