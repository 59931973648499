.card-img-top{
    width: 100px!important;
}
.card{
    align-items: center;
    border: none!important;
    height: 230px!important;
    width: 230px;
    border-radius: 20px!important;
    box-shadow: 0px 0px 12px 0px #e7e7e7;
}
.card:hover{
    background: rgba(255, 182, 199, 0.2);
    color: #D7264E;
}
.card-text{
    font-weight: 500;
}
.path-text{
    color: #b1b1b1;
    /* font-size: 12px; */
    /* letter-spacing: 2px; */
}
.fd-cal-text{
    font-size: 28px;
    font-weight: 600;
    color: #0073BB;
}
@media only screen and (min-width: 1020px) and (max-width: 1430px) {
    .card{
        height: 170px!important;
    }
    .card-text{
        font-size: 13px!important;
    }
    .card-img-top {
        width: 80px!important;
    }
  }
@media only screen and (min-width: 760px) and (max-width: 1020px) {
    .card{
        height: 164px!important;
    }
    .card-img-top {
        width: 75px!important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 760px) {
    .card-text{
        font-size: 12px;
    }
    .card{
        height: 118px!important;
        width: 118px;
    }
    .card-img-top {
        width: 60px!important;
    }
    .card-body{
        padding: 6px!important;
    }
  }

  @media only screen and (min-width: 300px) and (max-width: 370px) {
    .card-body{
        padding-bottom: 5px!important;
        padding-top: 5px!important;
    }
    .card{
        height: 90px!important;
    }
    .card-text{
        font-size: 8px!important;
    }
    .card-img-top {
      width: 40px!important;
      /* margin-left: 26px; */
  }
  }

  @media only screen and (min-width: 372px) and (max-width: 420px) {
    .card-body{
        padding-bottom: 5px!important;
        padding-top: 5px!important;
    }
    .card{
        height: 105px!important;
    }
    .card-text{
        font-size: 11px!important;
    }
    .card-img-top {
      width: 40px!important;
      /* margin-left: 26px; */
  }
  }

  @media only screen and (min-width: 420px) and (max-width: 760px) {
    .card-body {
        padding: 11px !important;
    }
}
@media only screen and (min-width: 420px) and (max-width: 760px) {
    .card-text {
        font-size: 10px !important;
    }
}
@media only screen and (min-width: 420px) and (max-width: 760px) {
    .card-img-top {
        width: 46px !important;
    }
}