select {
    border-top: none!important;
    border-left: none!important;
    border-right: none!important;
    border-color: #e3e3e3!important;
    /* color: #0073BB!important; */
    /* border-bottom: 1px solid #000!important; */
    width: 336px;
    height: 35px;
    /* border-radius: .3rem; */
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    background-size: 8px 7px, 8px 7px;
    background-repeat: no-repeat;
    background-position: calc(100% - 17px),
      calc(100% - 10px);
    padding: .2rem .6rem;
  }
  .one {
    background-image: linear-gradient(45deg, transparent 50%, #000 50%), 
      linear-gradient(135deg, #000 50%, transparent 50%);
      margin-right: 20px;
  }


  select:focus {
   border-color: 0px white !important;
   border: 0px solid white !important;
  }

  .accordion-item{
    border-top: none!important;
    border-left: none!important;
    border-right: none!important;
  }

  .bg-pink{
    background-color: #08136c22;
    color: #181718;
    font-weight: 700;
    border-radius: 5px;
    padding-left: 5px;
  }

  .bg-pink1{
    background-color: #08136c22 !important;
    color: #181718;
    font-weight: bold !important;
    border-radius: 5px;
    padding-left: 5px;
  }

  .bg-blue{
    background-color: #0073bb22;
    color: #0073bb;
    font-weight: 600;
    border-radius: 5px;
    padding-left: 5px;
  }

  @media only screen and (min-width:300px) and (max-width:420px) {
    select {
        width: 240px;
      }
      #PPFCal{
        margin-left: 10px!important;
      }
  }
  @media only screen and (min-width:420px) and (max-width:430px) {
    select {
        width: 300px;
      }
      #PPFCal{
        margin-left: 10px!important;
      }
  }
  @media only screen and (min-width:430px) and (max-width:770px) {
    select{
      width: 308px;
    }
  }
  @media only screen and (min-width:770px) and (max-width:1030px) {
    select{
      width: 224px;
    }
    .one{
      margin-right: 5px;
    }
  }

  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: #0073bbd7 !important;;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
    color: #ffffff  !important;
    
}

.btn-primary{
  background: #0073bbd7 !important;
  border: 1px solid #0073bbd7 !important;
  width: 100% !important;
}

.accordion-button::after{
  color: #ffffff !important;
}

.ta-textTitle{
  width: 45% !important;
}

#flexRadioDefault2 {
  height: 16px !important;
  width: 17px !important;
}
#flexRadioDefault1 {
  height: 16px !important;
  width: 17px !important;
}

.form-check-input:checked {
  background-color: #0073bbd7 !important;
  border-color: #0073bbd7 !important;
}

.form-check-input:checked {
  background-color: #0073bbd7 !important;
  border-color: #0073bbd7 !important;
  padding: 1px !important;
  border: 2px solid #e4dedeb5 !important;
}