.react-tabs__tab--selected{
    border-color: transparent!important;
    background-color: #ff656524;
}
.react-tabs__tab-list{
    border-bottom: none!important;
}
.same-heading{
    font-size: 20px!important;
    font-weight: 500;
    color: #0073BB;
}
.cep5-avya-select{
    width: 40px;
    border: 1px solid #a9a9a9!important;
}
.cep5-text-blue-bold {
    /* font-size: 18qpx!important; */
    font-weight: 700;
    color: #0d4fa3;
}
.cep5-text-blue-bold span{
    /* font-size: 18px!important; */
    font-weight: 700;
    color: #0d4fa3;
}

.astimate{
    border: 15px solid #e2e2e2;
    border-radius: 50px;
}
.saved-val{
    background-color: #FF5E14;
    border-radius: 50px;
    color: #fff;
    margin-right: 10px;
}
.cep5-list {
    list-style: disc;
    line-height: 1.5;
}

[id^="finances-tooltip"] {
    background: #f3f3f3;
    padding: 14px;
    color:black;
    font-size: 12px;
    display: inline-block;
    opacity: 1;
    position: relative;
    top: 10px;
    
    line-height: 1.5;
    transition: opacity 0.2s;
    -ms-transition: opacity 0.2s;
    -webkit-transition: opacity 0.2s; 
    -o-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
}

.tooltip-triangle {
    display: inline-block;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #dedfec;
    border-right: 7px solid transparent;
    position: relative;
    left: 94px;
    top:-31px;
}
.mx-auto
{
    text-align: center;
}


