.navbar{
    /* box-shadow: 5px 0px 12px 0px #e2e2e2; */
    background-color: #ffffff!important;
    width: 100% !important;
}
.navbar-expand-lg{
     z-index: 9999;
    position: fixed!important;
    width: 100%;
}
.main-logo img{
    width: 140px;
}
/* .main-logo{
    padding-left: 40px;
} */
.sub-name{
    font-size: 12px;
    line-height: 1.5;
}
.man-img{
    margin-right: 10px;
}



.sidenav {
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 1; /* Stay on top */
    top: 0;
    background-color: #e5e5e5; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    /* 0.5 second transition effect to slide in the sidenav */
}

/* The navigation menu links */
.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #343434;
    display: block;
    /* transition: 0.3s */
}

/* When you mouse over the navigation links, change their color */
/* .sidenav a:hover, .offcanvas a:focus{
    color: #f1f1f1;
} */

.sidenav a:hover, .offcanvas a:focus {
    color: #6AA6CE;
    font-weight: bold;
    /* list-style: gujarati; */
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
    transition: margin-left .5s;
    padding: 20px;
}
.sidenav {
    right: 0;
}
/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
/* @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
} */
.sidenav {
    right: 0;
}
.main-header{
    position: fixed;
}
.navbar-expand-lg {
    z-index: 9999;
    top: 0 !important;
    position: fixed!important;
    width: 100%;
}
.bg-light {
    height: 0px !important;
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}
.bg-light {
    background-color: #dc1a1a !important;
}
.navbar {
    height: 0px !important;
}