.tab {
    padding-top: 30px;
    float: left;
    width: 7%;
    background-color: #ffffff;
    box-shadow: 0px 0px 12px 0px #e2e2e2;
    position: fixed;
    z-index: 999 !important;
    height: 734px;
    overflow-y:auto;
    overflow-x: hidden;
    top: 0;
  }
  /* button:hover .tab{
    overflow-y: hidden!important;
  } */
  
.tab img{
    width: 40px;
}
  
  /* Style the buttons inside the tab */
  .tab button {
    padding: 18px 12px;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    background-color: #ffffff;
  }

  .tab button:hover .react-tooltip{
  left: 1000px !important;
  z-index: 99999999 !important;
  }
  
  /* Change background color of buttons on hover */
  /* .tab button:hover {
    background-color: #ddd;
  } */
  
  /* Create an active/current "tab button" class */
  .tab button.active {
    border-right: 2px solid #D7264E!important;
  }
  
  /* .mobile-tab button.active1 {
    border-top: 2px solid #D7264E!important;
  } */
  /* Style the tab content */
  .tabcontent {
    float: left;
    padding: 0px 12px;
    width: 93%;
    border-left: none;
    height: 300px;
  }

  .tab-img{
    margin-left: 10px;
  }

  .path-text{
    color: #b1b1b1;
    margin-top: 15px;
    /* font-size: 12px; */
  }
  .sip-cal{
    /* padding-left: 32px; */
  }
  .fd-cal-text{
    font-size: 28px;
    font-weight: 600;
    color: #0073BB;
  }


.input-range__label-container-fluid{
    display: none;
}
.background{
    background-color: #ffffff;
    border-radius: 10px;
}
.background input{
    background: rgba(0, 115, 187, 0.10);
    border: none;
    border-radius: 5px;
}
.background img{
    width: 300px;
}

/* mobile-css-start */

.mobile-tab{
    padding-top: 0px;
    float: left;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 999;
    background-color: #ffffff;
    box-shadow: 0px 0px 12px 0px #e2e2e2;
    overflow: auto;
}
.mobile-tab button {
    display: block;
    background-color: inherit;
    color: black;
    padding: 14px 13px;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    font-size: 10px;
}
.mobile-tab img{
    width: 30px;
}

.ppf-cal{
  padding-left: 32px;
}
@media only screen and (min-width: 425px) and (max-width: 1020px) {
    .tabcontent{
        width: 97%;
    }
    .mobile-tab button.active {
      border-top: 2px solid #D7264E!important;
      color: #D7264E !important;
    }
    .mobile-tab img {
      width: 30px;
      /* margin-left: 26px; */
  }
  }
  @media only screen and (min-width: 300px) and (max-width: 420px) {
    .mobile-tab button{
      padding: 14px 7px;
    }
    .mobile-tab button.active {
      border-top: 2px solid #D7264E!important;
      color: #D7264E !important;
  }
  }


.range-slider__tooltip {
    display: none !important;
  }

  .tab .react-tooltip{
    z-index: 9999 !important;
    width: 80px!important;
    font-size: 10px !important;
  }
  

  input{
    height:30px !important
  }
  *{
    font-size: 14px !important;
  }