body {
  margin: 0;
  font-family: poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #F6F6F6!important; */
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.background{
  /* margin-left: 50px !important; */
  border-radius: 0px !important;
}

.donut-chart {
  /* margin: auto !important; */
  /* text-align: center !important; */
  /* margin-left: 40px !important; */
}

.{
background: white !important;
/* margin-left: 50px !important; */
}
.rangeslider-horizontal{
  height: 2px !important;
  border-radius: 10px;
}

.apexcharts-tooltip-text-y-value{
  display: none !important;
}

.apexcharts-xaxistooltip-bottom {
  display: none !important;
}
 .rangeslider__handle {
  width: 14px !important;
  height: 14px !important;
  border-radius: 30px;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.ta-cursor{
  cursor: pointer;
}
.err-msg{
  color: #f33a3a;
  font-size: 13px !important;
}

.custome-row{
  margin-left: 80px !important;
}

@media only screen and (min-width:300px) and (max-width:420px) {
 .custome-row{
  margin-left: -0px !important;
}
}
@media only screen and (min-width:420px) and (max-width:430px) {
 .custome-row{
  margin-left: -0px !important;
}
}
@media only screen and (min-width:430px) and (max-width:770px) {
 .custome-row{
  margin-left: -0px !important;
}
}
@media only screen and (min-width:770px) and (max-width:1030px) {
 .custome-row{
  margin-left: 0px !important;
}
}
@media only screen and (min-width:1023px) and (max-width:1450px) {
  .custome-row{
   margin-left: 50px !important;
 }
}